import { FormEvent, useState } from 'react';
import { resetUserPassword } from 'stores/userStore';
import LoadingModal from './LoadingModal';
import styles from './styles/AuthModal.module.scss';
import Errors from './Errors';
import Label from './Label';
import Input from './Input';
import Button from './Button';
import { isEmailValid } from '../helpers/user';

interface PasswordResetFormProps {
  onCancel: () => void;
  onReset?: () => void;
}

function PasswordResetForm({ onCancel, onReset }: PasswordResetFormProps) {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState<string[] | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const emailValid = email.length > 3 && isEmailValid(email);

  function handleReset(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsSaving(true);
    resetUserPassword(email)
      .then(() => {
        onReset && onReset();
      })
      .catch(errors => {
        setErrors(errors);
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  return (
    <form onSubmit={handleReset}>
      <LoadingModal isOpen={isSaving} />
      <div className={styles.ModalTitle}>FORGOT PASSWORD</div>
      <div className={styles.PasswordText}>Please enter your email to reset your password</div>
      <Errors errors={errors} />
      <Label text="Email" />
      <Input type="text" placeholder="Enter Email" value={email} changeFunc={setEmail} modalSelect name="email" isChecked={emailValid} />
      <Button label="send reset link" isSubmit className={styles.AuthModalButton} />
      <div onClick={onCancel} className={styles.LoginLink}>
        Cancel
      </div>
    </form>
  );
}

export default PasswordResetForm;
