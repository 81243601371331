import Cookies from 'js-cookie';
import { useEffect } from 'react';

const COOKIE_NAME = 'mm-session-id';

export default function useMinuteMediaSessionCookie() {
  useEffect(() => {
    // keep existing UUID or generate new UUID
    const uuid = Cookies.get(COOKIE_NAME) || crypto.randomUUID();

    Cookies.set(COOKIE_NAME, uuid, {
      // expire after 30 minutes
      expires: new Date(new Date().getTime() + 30 * 60 * 1000)
    });
  }, []);
}
