import { ButtonHTMLAttributes } from 'react';
import Link, { LinkProps } from 'components/Link';

export type ClickableProps = LinkProps | ButtonHTMLAttributes<HTMLButtonElement>;

function isAnchorProps(props: ClickableProps): props is LinkProps {
  return (props as LinkProps).href !== undefined;
}

export default function Clickable(props: ClickableProps) {
  if (isAnchorProps(props)) {
    return <Link {...props} />;
  } else {
    return <button {...props} type={props.type || 'button'} />;
  }
}
