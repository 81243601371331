import { useState } from 'react';

interface ToggleableState<PropsType> {
  isOpen: boolean;
  props: PropsType;
}

interface ToggleableMethods<PropsType> {
  handleOpen: (props: PropsType) => void;
  handleClose: () => void;
  handleToggle: () => void;
}

export default function useToggleableProps<PropsType>(initialIsOpen?: boolean): [ToggleableState<PropsType>, ToggleableMethods<PropsType>] {
  const [state, setState] = useState<ToggleableState<PropsType>>({ isOpen: !!initialIsOpen, props: {} as PropsType });

  return [
    state,
    {
      handleOpen: (props: PropsType) => setState({ props, isOpen: true }),
      handleClose: () => setState(state => ({ ...state, isOpen: false })),
      handleToggle: () => setState(state => ({ ...state, isOpen: !state.isOpen }))
    }
  ];
}
