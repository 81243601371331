import Store, { withStore, useStore } from 'stores';
import { AccountType, RegisterParams, UpdateAccountParams } from '@sblive-sports/api-client-js';
import expose from 'helpers/expose';
import { localCache } from 'helpers/cache';
import client from 'helpers/client';
import { fetchUserEntities, clearUserEntities } from 'stores/userEntitiesStore';
import { IpInfoStoreState } from './ipInfoStore';
import { triggerEvent, LOGIN, LOGOUT, REGISTER } from 'analytics/events';
import * as Sentry from '@sentry/browser';
import { SBLIVE_WEB_BASE_URL } from 'constants.js.erb';

export interface UserStoreState {
  isResolved: boolean;
  account?: AccountType;
  hasTournamentManagerAccess?: boolean;
  hasEnhancedSharingAccess?: boolean;
  isLoggedIn?: boolean;
  isAdmin?: boolean;
  canClaim?: boolean;
  defaultState?: string; //TODO stateAbrevType
  superUserAccount?: AccountType;
}

export const OFFICIAL = 1;
export const COACH = 4;
export const HIGH_SCHOOL_COACH = 9;

export const USER_CACHE_KEY = 'user';

const userStore = new Store<UserStoreState>({ isResolved: false }, USER_CACHE_KEY);

export const WithUser = withStore(userStore);

export function useUser() {
  return useStore(userStore);
}

function handleResponse(account?: AccountType): UserStoreState {
  if (account !== undefined) {
    try {
      Sentry.setUser({
        id: account.id,
        email: account.email,
        username: account.username
      });
    } catch (error) {
      console.info('Sentry Error:', error);
    }
  }

  return {
    isResolved: true,
    account,
    isLoggedIn: !!account,
    isAdmin: account?.admin,
    hasTournamentManagerAccess: account?.admin || account?.roles?.includes('tournament_manager'),
    hasEnhancedSharingAccess: account?.admin || account?.roles?.includes('enhanced_sharing'),
    canClaim: !!account?.user_type_id && [OFFICIAL, COACH, HIGH_SCHOOL_COACH].includes(account.user_type_id)
  };
}

export function authenticateUser() {
  return client
    .validate()
    .then(account => {
      userStore.setState(handleResponse(account));
      fetchUserEntities();
      return account;
    })
    .catch(() => {
      userStore.setState(handleResponse());
      clearUserEntities();
    });
}

export function registerUser(params: RegisterParams) {
  return client.register(params, window.location.href).then(account => {
    userStore.setState(handleResponse(account));
    triggerEvent(REGISTER, { account });
    return account;
  });
}

export function loginUser(email: string, password: string) {
  return client.login(email, password).then(account => {
    userStore.setState(handleResponse(account));
    fetchUserEntities();
    triggerEvent(LOGIN, { account });
    return account;
  });
}

export function logoutUser() {
  try {
    Sentry.setUser(null);
  } catch (error) {
    console.info('Sentry Error:', error);
  }

  return client.logout().then(() => {
    userStore.setState({ ...handleResponse(), superUserAccount: undefined });
    clearUserEntities();
    triggerEvent(LOGOUT);
  });
}

export function updateUserAccount(params: UpdateAccountParams) {
  return client.updateAccount(params).then(account => {
    userStore.setState(handleResponse(account));
    return account;
  });
}

export function updateUserPassword(current_password: string, password: string, password_confirmation: string) {
  return client.updatePassword(current_password, password, password_confirmation);
}

export function newPassword(resetPasswordToken: string, password: string, passwordConfirmation: string) {
  return client.newPassword(resetPasswordToken, password, passwordConfirmation).then(account => {
    userStore.setState(handleResponse(account));
    return account;
  });
}

export function resetUserPassword(email: string) {
  return client.resetPassword(email, `${SBLIVE_WEB_BASE_URL}/password/new`, 'web');
}

export function requestUserAccountDeletion() {
  return client.deleteAccount();
}

export function takeOverUser(userId: number) {
  if (!userStore.state.isAdmin) throw 'Must be `admin` account to take over a user';

  return client.assumeAccount(userId).then(account => {
    clearUserEntities();
    userStore.setState({ ...handleResponse(account), superUserAccount: userStore.state.account });
    fetchUserEntities();
    return account;
  });
}

export function releaseUser() {
  return client.releaseAccount().then(() => {
    clearUserEntities();
    userStore.setState({ ...handleResponse(userStore.state.superUserAccount), superUserAccount: undefined });
    fetchUserEntities();
  });
}

expose('userStore', userStore);
expose('takeOverUser', takeOverUser);
expose('releaseUser', releaseUser);
expose('setMyState', function (stateAbbrev: string) {
  //TODO stateAbbrevType
  const IPInfo = localCache.get<IpInfoStoreState>('ipInfoStore');
  localCache.set('ipInfoStore', { status: 'RESOLVED', data: { region_code: stateAbbrev } });
  location.reload();
});

export default userStore;
