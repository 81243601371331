export default function fetchJSON<ResponseType>({
  url,
  method = 'get',
  data
}: {
  url: string;
  method?: 'get' | 'post' | 'put';
  data?: any;
}): Promise<ResponseType> {
  const options = {
    headers: { Accept: 'application/json' }
  } as RequestInit;

  if (method == 'get') {
    if (data) url = [url, new URLSearchParams(data).toString()].join('?');
  } else {
    options.method = method;
    options.body = JSON.stringify(data);
  }

  return fetch(url, options).then(response => response.json());
}
