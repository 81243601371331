import ReactDOM from 'react-dom';
import styles from './styles/LoadingModal.module.scss';

const LoadingModal = ({ isOpen }: { isOpen: boolean }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={styles.LoadingModal}>
      <div className={styles.LoadingModalContent}>
        <div className={styles.LoadingRing}>
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>,
    document.body
  );
};

export default LoadingModal;
