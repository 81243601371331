import { useRef, useEffect, DependencyList } from 'react';

export default function useThrottledEffect(effect: () => void, dependencies: DependencyList) {
  const changeTimer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (changeTimer.current) clearTimeout(changeTimer.current);
    changeTimer.current = setTimeout(effect, 300);
    return () => {
      if (changeTimer.current) clearTimeout(changeTimer.current);
    };
  }, dependencies);
}
