import styles from './styles/Spinner.module.scss';
import cn from 'classnames';

export enum SpinnerLocation {
  FIXED_TOP_RIGHT = 'FixedTopRight',
  SCROLL_FOOTER = 'ScrollFooter',
  INLINE = 'Inline',
  FIXED_BOTTOM_RIGHT = 'FixedBottomRight',
  CENTER = 'Center'
}

export default function Spinner({ className, location = SpinnerLocation.INLINE }: { className?: string; location?: SpinnerLocation }) {
  return (
    <div className={cn(styles.Container, location !== SpinnerLocation.INLINE && styles[location], className)}>
      <div className={styles.Spinner} />
    </div>
  );
}
