import styles from './styles/AuthModal.module.scss';
import { useState } from 'react';
import Button from './Button';
import { isEmailValid, isUsernameValid } from 'helpers/user';
import Label from './Label';
import Input from './Input';
import { registerUser } from 'stores/userStore';
import LoadingModal from './LoadingModal';
import Errors from './Errors';
import FlexContainer from './FlexContainer';
import { ATHLETIC_DIRECTOR, COACH, FAN } from 'constants/UserTypes';
import useThrottledEffect from 'hooks/useThrottledEffect';
import queries from 'queries';

interface UserTypeOptionProps {
  userTypeId: number | null;
  value: number;
  title: string;
  detail: string;
  icon: string;
  onChange: (o: any) => void;
}

interface RegisterFormState {
  email: string;
  username: string;
  password: string;
}

const UserTypeOption = ({ userTypeId, value, title, detail, icon, onChange }: UserTypeOptionProps) => (
  <div className={`${styles.UserTypeOption} ${userTypeId === value ? styles.Active : ''}`} onClick={() => onChange(value)}>
    <div className={styles[icon as keyof typeof styles]} />
    <div className={styles.Title}>{title}</div>
    <div className={styles.Detail}>{detail}</div>
  </div>
);

interface UserTypeFormProps {
  prevStep: (o: any) => void;
  onSubmit: (o: any) => void;
}

const UserTypeForm = ({ prevStep, onSubmit }: UserTypeFormProps) => {
  const [userTypeId, setUserTypeId] = useState<number | null>(null);
  return (
    <>
      <div className={styles.UserType}>
        <div className={styles.Options}>
          <UserTypeOption
            userTypeId={userTypeId}
            value={FAN}
            title="Fan"
            icon="FanIcon"
            detail="Follow teams and games. Select this option if you are not a school or team owner."
            onChange={setUserTypeId}
          />
          <UserTypeOption
            userTypeId={userTypeId}
            value={COACH}
            title="Coach"
            icon="CoachIcon"
            detail="Claim and manage your team, live score games, invite users, and more."
            onChange={setUserTypeId}
          />
          <UserTypeOption
            userTypeId={userTypeId}
            value={ATHLETIC_DIRECTOR}
            title="Athletic Director"
            icon="AthleticDirectorIcon"
            detail="Claim and manage your school, invite coaches, and more."
            onChange={setUserTypeId}
          />
        </div>
      </div>
      <FlexContainer className={styles.RegisterButtons}>
        <div>
          <Button onClick={prevStep} label="back" className={styles.RegisterButton} type="white" />
        </div>
        <div>
          <Button label="done" className={styles.RegisterButton} disabled={!userTypeId} onClick={() => onSubmit(userTypeId)} />
        </div>
      </FlexContainer>
    </>
  );
};

interface UserInfoFormProps {
  handleNext: (o: any) => void;
  onClickLogin: () => void;
  currentState: RegisterFormState;
}

function UserInfoForm({ handleNext, currentState, onClickLogin }: UserInfoFormProps) {
  const [email, setEmail] = useState(currentState.email);
  const [password, setPassword] = useState(currentState.password);
  const [username, setUsername] = useState(currentState.username);
  const [emailTaken, setEmailTaken] = useState(true);
  const [usernameTaken, setUsernameTaken] = useState(true);

  useThrottledEffect(() => {
    setEmailTaken(true);
    if (email.length > 3 && isEmailValid(email)) {
      queries.registerUserQuery({ email }).then(({ user }) => setEmailTaken(user));
    }
  }, [email]);

  useThrottledEffect(() => {
    setUsernameTaken(true);
    if (username.length > 3 && isUsernameValid(username)) {
      queries.registerUserQuery({ username }).then(({ user }) => setUsernameTaken(user));
    }
  }, [username]);

  const emailValid = email.length > 3 && isEmailValid(email);
  const passwordValid = password.length > 8;
  const usernameValid = username.length > 3 && isUsernameValid(username);
  const formValid = emailValid && passwordValid && usernameValid;

  function handleClick() {
    if (!formValid) return;
    handleNext({
      email: email,
      password: password,
      username: username
    });
  }

  return (
    <>
      <Label text="Email" />
      <Input
        type="text"
        placeholder="Enter Email"
        value={email}
        changeFunc={setEmail}
        modalSelect
        name="email"
        isChecked={emailValid && !emailTaken}
      />
      <Label text="Password" />
      <Input
        type="password"
        placeholder="Enter Password"
        value={password}
        changeFunc={setPassword}
        modalSelect
        name="password"
        isChecked={passwordValid}
      />
      <Label text="Username" />
      <Input
        type="text"
        placeholder="Enter Username"
        value={username}
        changeFunc={setUsername}
        modalSelect
        name="username"
        isChecked={usernameValid && !usernameTaken}
      />
      <Button label="next" onClick={handleClick} className={styles.AuthModalButton} disabled={!formValid} />
      <div>
        Already have an account? &nbsp;
        <div onClick={onClickLogin} className={styles.LoginLink}>
          SIGN IN
        </div>
      </div>
    </>
  );
}

interface RegisterFormProps {
  onBack?: () => void;
  onNext?: () => void;
  onClickLogin: () => void;
  onRegister: (userType?: number) => void;
}

function RegisterForm({ onBack, onNext, onClickLogin, onRegister }: RegisterFormProps) {
  const [errors, setErrors] = useState<string[] | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [currentStep, setCurrentStep] = useState<1 | 2>(1);
  const [currentState, setCurrentState] = useState({
    email: '',
    username: '',
    password: ''
  });

  function handleRegister(userType: number | null) {
    if (!userType) return;

    setIsSaving(true);
    registerUser({
      email: currentState.email,
      username: currentState.username,
      password: currentState.password,
      password_confirmation: currentState.password,
      user_type_id: userType
    })
      .then(() => {
        onRegister(userType);
      })
      .catch(errors => {
        setErrors(errors);
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  function handleNext(o: any) {
    setCurrentState(o);
    setCurrentStep(2);
    onNext && onNext();
  }

  function handleBack() {
    setCurrentStep(1);
    onBack && onBack();
  }

  return (
    <form onSubmit={handleNext}>
      <LoadingModal isOpen={isSaving} />
      <div className={styles.ModalTitle}>Register</div>
      <Errors errors={errors} />
      {currentStep === 1 ? (
        <UserInfoForm onClickLogin={onClickLogin} handleNext={handleNext} currentState={currentState} />
      ) : (
        <UserTypeForm prevStep={handleBack} onSubmit={handleRegister} />
      )}
    </form>
  );
}

export default RegisterForm;
