declare global {
  // TODO where to put this?
  interface Window {
    sbl?: {
      [key: string]: any;
    };
  }
}

export default function expose(name: string, obj: any) {
  if (typeof window != 'undefined') {
    window.sbl = window.sbl || {};
    window.sbl[name] = obj;
  }
}
