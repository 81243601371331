import expose from 'helpers/expose';

function getKey(key: string, prefix: boolean = true) {
  if (!prefix) return key;
  return `sbl_${key}`;
}

export const [sessionCache, localCache] = (['sessionStorage', 'localStorage'] as ('sessionStorage' | 'localStorage')[]).map(storageType => {
  function getStorage() {
    if (typeof window === 'undefined') return;
    return window[storageType] as Storage;
  }

  return {
    set: (key: string, data: any, prefix: boolean = true) => {
      getStorage()?.setItem(getKey(key, prefix), JSON.stringify(data));
    },
    get: function <ValueType>(key: string, prefix: boolean = true) {
      const value = getStorage()?.getItem(getKey(key, prefix));
      if (value === null || value === undefined) return undefined;
      return JSON.parse(value) as ValueType;
    },
    delete: function (key: string, prefix: boolean = true) {
      getStorage()?.removeItem(getKey(key, prefix));
    }
  };
});

expose('sessionCache', sessionCache);
expose('localCache', localCache);
