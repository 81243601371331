import { CSSProperties } from 'react';
import { IconType } from '.';

interface ChevronProps extends IconType {
  style?: CSSProperties;
  direction?: 'up' | 'down' | 'left' | 'right';
}

export default function Chevron({ className = '', color = '#fff', direction = 'right', height = 13, style, width = 7 }: ChevronProps) {
  if (direction === 'up')
    return (
      <svg width={width} height={height} className={className} style={style} viewBox="0 0 11.388 6.18">
        <path
          d="M36.949,165.846a1,1,0,0,0,1.343,0l4.743-4.413a.841.841,0,0,0,0-1.25,1,1,0,0,0-1.343,0l-4.073,3.79-4.073-3.787a1,1,0,0,0-1.343,0,.841.841,0,0,0,0,1.25l4.743,4.413Z"
          transform="translate(43.313 166.105) rotate(180)"
          fill={color}
        />
      </svg>
    );
  if (direction === 'left')
    return (
      <svg width={width} height={height} className={className} style={style} viewBox="0 0 7.607 13.94">
        <path
          fill={color}
          d="M29.645,177.564l-6.02,5.667a.856.856,0,0,1-.653.293.951.951,0,0,1-.653-.26l-6.02-5.7a.951.951,0,1,1,1.307-1.382l5.366,5.081,5.366-5.085a.95.95,0,0,1,1.344.037A.96.96,0,0,1,29.645,177.564Z"
          transform="translate(183.525 -16.001) rotate(90)"
        />
      </svg>
    );
  if (direction === 'right')
    return (
      <svg width={width} height={height} className={className} style={style} viewBox="0 0 6.18 11.388">
        <path
          fill={color}
          id="angle-down-solid"
          d="M36.949,165.846a1,1,0,0,0,1.343,0l4.743-4.413a.841.841,0,0,0,0-1.25,1,1,0,0,0-1.343,0l-4.073,3.79-4.073-3.787a1,1,0,0,0-1.343,0,.841.841,0,0,0,0,1.25l4.743,4.413Z"
          transform="translate(-159.925 43.313) rotate(-90)"
        />
      </svg>
    );
  return (
    <svg width={width} height={height} className={className} style={style} viewBox="0 0 11.388 6.18">
      <path
        d="M36.949,165.846a1,1,0,0,0,1.343,0l4.743-4.413a.841.841,0,0,0,0-1.25,1,1,0,0,0-1.343,0l-4.073,3.79-4.073-3.787a1,1,0,0,0-1.343,0,.841.841,0,0,0,0,1.25l4.743,4.413Z"
        transform="translate(-31.925 -159.925)"
        fill={color}
      />
    </svg>
  );
}
