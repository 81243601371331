import copy from 'copy-to-clipboard';

export async function copyText(text: string) {
  try {
    if (navigator?.clipboard) {
      await navigator.clipboard.writeText(text);
    } else {
      copy(text);
    }
    // TODO toast messages
    alert('Copied to Clipboard');
  } catch (err) {
    console.error('Error in copying text: ', err);
    alert('Failed to copy to Clipboard');
  }
}
