import fetchJSON from 'helpers/fetchJSON';
import { getSdk } from './sdk-javascript';
import { getRubySdk } from './sdk-ruby';
import client from 'helpers/client';
import { SBLIVE_WEB_BASE_URL } from 'constants.js.erb';

export * from './sdk-javascript';
export * from './sdk-ruby';

const jsRequester = async <R, V>(query: string, variables: V, headers: Record<string, string> = {}): Promise<R> => {
  return client.graphQuery(query, variables, headers);
};

const queries = getSdk(jsRequester);

const rubyRequester = async <R, V>(queryName: string, ttl: number, variables?: V): Promise<R> => {
  const data: any = { ttl };
  if (variables) {
    data.variables = JSON.stringify(variables);
  }

  return fetchJSON<R>({
    url: `${SBLIVE_WEB_BASE_URL}/queries/${queryName}.json`,
    data
  });
};

export const rubyQueries = getRubySdk(rubyRequester);

export default queries;
