import { sessionCache, localCache } from 'helpers/cache';
import isEqual from 'helpers/isEqual';
import { useState, useEffect, Component, ReactNode, Dispatch, SetStateAction } from 'react';

type StoreSubscriberType<StoreStateType> = Dispatch<SetStateAction<StoreStateType>>;

class Store<StoreStateType extends {}> {
  cacheKey?: string;
  defaultState: StoreStateType;
  state: StoreStateType;
  cache: typeof sessionCache | typeof localCache;
  private subscribers: StoreSubscriberType<StoreStateType>[] = [];

  constructor(defaultState: StoreStateType, cacheKey?: string, cache?: typeof sessionCache | typeof localCache) {
    this.cacheKey = cacheKey;
    this.defaultState = defaultState;
    this.cache = cache || sessionCache;
    this.state = (this.cacheKey && this.cache.get<StoreStateType>(this.cacheKey)) || defaultState;
  }

  setState(state: Partial<StoreStateType>) {
    const newState = { ...this.state, ...state };
    if (isEqual(this.state, newState)) return;
    this.state = newState;
    if (this.cacheKey) this.cache.set(this.cacheKey, this.state);
    this.publish();
  }

  subscribe(setter: StoreSubscriberType<StoreStateType>) {
    if (!this.subscribers.includes(setter)) {
      this.subscribers.push(setter);
      setter(this.state);
      return setter;
    }
  }

  unsubscribe(setter: StoreSubscriberType<StoreStateType>) {
    this.subscribers = this.subscribers.filter(subscriber => subscriber !== setter);
  }

  publish() {
    this.subscribers.forEach(subscriber => {
      subscriber(this.state);
    });
  }
}

export function useStore<StoreStateType extends {}>(store: Store<StoreStateType>) {
  const [state, setState] = useState<StoreStateType>(store.defaultState);
  useEffect(() => {
    store.subscribe(setState);
    return () => {
      store.unsubscribe(setState);
    };
  }, []);
  return state;
}

export function withStore<StoreStateType extends {}>(store: Store<StoreStateType>) {
  return class extends Component<{ render: (state: StoreStateType) => ReactNode }> {
    state = store.defaultState;
    setter?: StoreSubscriberType<StoreStateType>;

    componentDidMount() {
      this.setter = store.subscribe(this.setState.bind(this));
    }

    componentWillUnmount() {
      if (this.setter) store.unsubscribe(this.setter);
    }

    render() {
      return this.props.render(this.state);
    }
  };
}

export default Store;
