import SBLiveClient from '@sblive-sports/api-client-js';
import { API_BASE_URL } from 'constants.js.erb';
import { localCache } from 'helpers/cache';

const AUTH_HEADERS_STORAGE_KEY = 'authHeaders';

export default new SBLiveClient({
  domain: API_BASE_URL,
  authHeaders: localCache.get(AUTH_HEADERS_STORAGE_KEY) || localCache.get(AUTH_HEADERS_STORAGE_KEY, false),
  onChangeAuthHeaders: headers => {
    if (localCache.get(AUTH_HEADERS_STORAGE_KEY, false)) localCache.delete(AUTH_HEADERS_STORAGE_KEY, false);

    if (!headers) {
      localCache.delete(AUTH_HEADERS_STORAGE_KEY);
    } else {
      localCache.set(AUTH_HEADERS_STORAGE_KEY, headers);
    }
  }
});
