import styles from 'components/styles/FlexContainer.module.scss';
import { CSSProperties, ReactNode } from 'react';
import cn from 'classnames';

interface FlexContainerProps {
  className?: string;
  column?: boolean;
  children: ReactNode;
  flexBasis?: number;
  fullWidth?: boolean;
  style?: CSSProperties;
}

function FlexContainer({ className, column, children, flexBasis, fullWidth, style }: FlexContainerProps) {
  const containerStyles = { flexBasis: flexBasis ? `${flexBasis}%` : undefined, width: fullWidth ? '100%' : undefined, ...style };
  return (
    <div style={containerStyles} className={cn(styles.FlexContainer, className, { [styles.FlexContainerColumn]: column })}>
      {children}
    </div>
  );
}

export default FlexContainer;
