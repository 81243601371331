import styles from 'components/styles/Label.module.scss';
import cn from 'classnames';

interface LabelProps {
  text: string;
  className?: string;
}

function Label({ text, className }: LabelProps) {
  return <div className={cn(styles.Label, className)}>{text}</div>;
}

export default Label;
