import { FormEvent, useState } from 'react';
import { isEmailValid } from 'helpers/user';
import { loginUser } from 'stores/userStore';
import LoadingModal from './LoadingModal';
import styles from './styles/AuthModal.module.scss';
import Errors from './Errors';
import Label from './Label';
import Input from './Input';
import Button from './Button';

interface LoginFormProps {
  onClickForgotPassword: () => void;
  onClickRegister: () => void;
  onLogin: () => void;
}

function LoginForm({ onLogin, onClickForgotPassword, onClickRegister }: LoginFormProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<string[] | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const emailValid = email.length > 3 && isEmailValid(email);
  const passwordValid = password.length > 6;

  function handleSignIn() {
    if (!emailValid || !passwordValid) return;
    setIsSaving(true);

    loginUser(email, password)
      .then(() => {
        onLogin();
      })
      .catch(errors => {
        setErrors(errors);
      })
      .finally(() => {
        setIsSaving(false);
      });
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSignIn();
      }}
    >
      <LoadingModal isOpen={isSaving} />
      <div className={styles.ModalTitle}>Sign In</div>
      <Errors errors={errors} />
      <Label text="Email" />
      <Input
        type="text"
        data-automation-id="authEmail"
        placeholder="Enter Email"
        value={email}
        changeFunc={setEmail}
        modalSelect
        name="email"
        isChecked={emailValid}
      />
      <Label text="Password" />
      <Input
        type="password"
        data-automation-id="authPassword"
        placeholder="Enter Password"
        value={password}
        changeFunc={setPassword}
        modalSelect
        name="password"
        isChecked={passwordValid}
      />
      <Button label="sign in" isSubmit className={styles.AuthModalButton} />
      <div onClick={onClickForgotPassword} className={styles.LoginLink}>
        Forgot Password?
      </div>
      <div>
        New to Scorebook Live? &nbsp;
        <div data-automation-id="signup" onClick={onClickRegister} className={styles.LoginLink}>
          SIGN UP
        </div>
      </div>
    </form>
  );
}

export default LoginForm;
