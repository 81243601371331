import { AnchorHTMLAttributes, useEffect, useState, MouseEvent } from 'react';
import { useApplicationContainer } from 'containers/ApplicationContainer';
import { interceptFanAppUrl } from 'helpers/fanAppRouting';

export enum BackParam {
  PERSIST = 'PERSIST',
  ADD = 'ADD'
}

export type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & { back?: BackParam };

export default function Link({ href: initialHref, onClick, back, ...props }: LinkProps) {
  const [, { rewriteUrl }] = useApplicationContainer();
  const [href, setHref] = useState(rewriteUrl(initialHref));

  useEffect(() => {
    const newHref = rewriteUrl(initialHref, back);
    if (href === newHref) return;

    setHref(newHref);
  }, [initialHref, back, rewriteUrl]);

  function handleClick(e: MouseEvent<HTMLAnchorElement>) {
    const intercepted = href && interceptFanAppUrl(href);

    if (intercepted) e.preventDefault();

    onClick && onClick(e);
  }

  return <a href={href} onClick={handleClick} {...props} />;
}
