import styles from 'components/styles/Button.module.scss';
import { ReactNode, MouseEvent } from 'react';
import cn from 'classnames';
import Link from './Link';

export type ButtonType = 'negative' | 'white' | 'grey' | 'v2' | 'GreyOnGrey' | 'BlackOnWhite' | 'BlackOnGrey' | 'WhiteOnBlack';

interface ButtonProps {
  label: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  className?: string;
  type?: ButtonType;
  isSubmit?: boolean;
  style?: any;
  autoId?: string;
  fullWidth?: boolean;
  href?: string;
}

function Button({ label, onClick, className, disabled, type, isSubmit, autoId, fullWidth, href, ...otherProps }: ButtonProps) {
  const button = (
    <button
      data-automation-id={autoId}
      type={isSubmit ? 'submit' : 'button'}
      onClick={!disabled && onClick ? onClick : undefined}
      {...otherProps}
      className={cn(
        styles.Button,
        {
          [styles.ButtonDisabled]: disabled,
          [styles.ButtonNegative]: type === 'negative',
          [styles.ButtonWhite]: type === 'white',
          [styles.ButtonGrey]: type === 'grey',
          [styles.ButtonV2]: type === 'v2',
          [styles.ButtonGreyOnGrey]: type === 'GreyOnGrey',
          [styles.ButtonBlackOnWhite]: type === 'BlackOnWhite',
          [styles.ButtonBlackOnGrey]: type === 'BlackOnGrey',
          [styles.ButtonWhiteOnBlack]: type === 'WhiteOnBlack',
          [styles.ButtonFullWidth]: fullWidth
        },
        className
      )}
    >
      {label}
    </button>
  );

  if (href)
    return (
      <Link href={href} className={cn({ [styles.ButtonFullWidth]: fullWidth })}>
        {button}
      </Link>
    );
  return button;
}

export default Button;
