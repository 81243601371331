import cn from 'classnames';
import styles from './styles/Errors.module.scss';

interface ErrorsProps {
  errors?: string[] | null;
  className?: string;
}

function Errors({ errors, className }: ErrorsProps) {
  if (!errors || errors.length === 0) return null;

  return (
    <div className={cn(styles.Errors, className)}>
      {errors.map((error, index) => (
        <p key={index}>{error}</p>
      ))}
    </div>
  );
}

export default Errors;
