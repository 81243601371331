import styles from 'components/styles/Input.module.scss';
import { InputHTMLAttributes } from 'react';
import cn from 'classnames';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  disabled?: boolean;
  type: 'text' | 'number' | 'password';
  inputSize?: 'large' | 'small';
  changeFunc: (v: string) => void;
  modalSelect?: boolean;
  isChecked?: boolean;
}

function Input({ className, type, inputSize, changeFunc, modalSelect, isChecked, ...props }: InputProps) {
  return (
    <input
      type={type}
      className={cn(styles.Input, className, {
        [styles.InputSmall]: inputSize === 'small',
        [styles.ModalSelect]: modalSelect,
        [styles.Checked]: isChecked
      })}
      onChange={e => changeFunc(e.target.value)}
      {...props}
    />
  );
}

export default Input;
