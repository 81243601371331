import { IconType } from '.';

interface CloseProps extends IconType {
  strokeWidth?: number;
}

export const Close = ({ height, width, className, color, strokeWidth }: CloseProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14.828 14.828" className={className}>
    <g id="Close" transform="translate(1.414 1.414)">
      <line
        id="Line_835"
        data-name="Line 835"
        x2="16.971"
        transform="translate(12 12) rotate(-135)"
        fill="none"
        stroke={color || '#fff'}
        strokeLinecap="round"
        strokeWidth={strokeWidth || 2}
      />
      <line
        id="Line_837"
        data-name="Line 837"
        x2="16.971"
        transform="translate(12 0) rotate(135)"
        fill="none"
        stroke={color || '#fff'}
        strokeLinecap="round"
        strokeWidth={strokeWidth || 2}
      />
    </g>
  </svg>
);

export default Close;
