import lazy from 'helpers/lazy';
import { PlacementType, SizeType, SponsorType } from 'components/Sponsor';
import { AccountType } from '@sblive-sports/api-client-js';

export const GOOGLE_AD_VIEW = 'GOOGLE_AD_VIEW';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const SPONSOR_CLICK = 'SPONSOR_CLICK';
export const SPONSOR_VIEW = 'SPONSOR_VIEW';
export const SCOREBOARD_NEXT_PAGE = 'SCOREBOARD_NEXT_PAGE';

interface Events {
  [GOOGLE_AD_VIEW]: {
    placement: PlacementType;
    size: SizeType;
  };
  [LOGIN]: {
    account: AccountType;
  };
  [LOGOUT]: undefined;
  [REGISTER]: {
    account: AccountType;
  };
  [SCOREBOARD_NEXT_PAGE]: undefined;
  [SPONSOR_CLICK]: {
    placement: PlacementType;
    size: SizeType;
    sponsor: SponsorType;
  };
  [SPONSOR_VIEW]: {
    placement: PlacementType;
    size: SizeType;
    sponsor: SponsorType;
  };
}

const eventListeners: {
  [key in keyof Events]?: ((eventData: Events[key]) => void)[];
} = {};

export function triggerEvent<T extends keyof Events>(eventType: T, eventData?: Events[T]) {
  eventListeners[eventType]?.forEach(callback => {
    // @ts-ignore: TODO figure out how to type this correctly. issue with hash map value definition
    lazy(() => callback(eventData));
  });
}

export function onEvent<T extends keyof Events>(eventType: T, callback: (eventData: Events[T]) => void) {
  eventListeners[eventType] = eventListeners[eventType] || [];
  // @ts-ignore: TODO figure out how to type this correctly. issue with hash map value definition
  eventListeners[eventType]!.push(callback);
}
