import styles from 'components/styles/AuthModal.module.scss';
import modalStyles from 'components/styles/Modal.module.scss';
import { useState } from 'react';
import FlexContainer from './FlexContainer';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import Close from 'icons/Close';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import PasswordResetForm from './PasswordResetForm';
import useScreenSize from 'hooks/useScreenSize';

interface AuthModalProps {
  onClose: () => void;
  type: AuthModalType;
}

export interface AuthFormType {
  onClose: () => void;
  setModalType: (t: AuthModalType) => void;
}

export type AuthModalType = 'login' | 'register' | 'register2' | 'password';

function AuthModal({ onClose, type }: AuthModalProps) {
  const [modalType, setModalType] = useState(type);
  const { isSmallScreen } = useScreenSize();

  function makeComponent() {
    if (modalType === 'register' || modalType === 'register2') {
      return (
        <RegisterForm
          onRegister={onClose}
          onBack={() => setModalType('register')}
          onNext={() => setModalType('register2')}
          onClickLogin={() => setModalType('login')}
        />
      );
    } else if (modalType == 'password') {
      return <PasswordResetForm onCancel={onClose} onReset={onClose} />;
    } else {
      return (
        <LoginForm
          onClickForgotPassword={() => setModalType('password')}
          onClickRegister={() => setModalType('register')}
          onLogin={onClose}
        />
      );
    }
  }

  return ReactDOM.createPortal(
    <div className={modalStyles.ModalContainer} onClick={onClose}>
      <div className={cn(modalStyles.Modal, styles.AuthModal)} onClick={e => e.stopPropagation()}>
        <FlexContainer className={styles.ContainerWrapper}>
          {!isSmallScreen && (
            <div
              className={cn(styles.BackgroundLeft, {
                [styles.Register]: modalType === 'register',
                [styles.PickType]: modalType === 'register2',
                [styles.ForgotPassword]: modalType === 'password'
              })}
            >
              <div className={styles.LogoCircle} />
            </div>
          )}
          <FlexContainer column className={styles.AuthModalContent}>
            <div className={cn(modalStyles.ModalHeader, styles.AuthModalHeader)}>
              <div className={modalStyles.ModalClose} onClick={onClose}>
                <Close height={16} width={16} color="#000" strokeWidth={3} />
              </div>
            </div>
            <div className={styles.AuthModalComponent}>
              {isSmallScreen && <div className={styles.LogoCircle} />}
              {makeComponent()}
            </div>
          </FlexContainer>
        </FlexContainer>
      </div>
    </div>,
    document.body
  );
}

export default AuthModal;
