import compact from './compact';

const DUMMY_ORIGIN = 'https://example.com';

function getUrlObj(url: string) {
  if (url.startsWith('http')) {
    return new URL(url);
  }

  return new URL(`${DUMMY_ORIGIN}${url}`);
}

export function getUrlQueryStringParam(url: string, key: string) {
  return getUrlObj(url).searchParams.get(key);
}

export type QueryStringParams = Record<string, string | number | boolean | undefined | null>;

export function makeUrl(url: string, params: QueryStringParams = {}) {
  const urlObj = getUrlObj(url);

  Object.keys(params).forEach(key => {
    const value = params[key];
    if (value !== undefined && value !== null) {
      urlObj.searchParams.set(key, value.toString());
    } else {
      urlObj.searchParams.delete(key);
    }
  });

  return [urlObj.origin !== DUMMY_ORIGIN && urlObj.origin, urlObj.pathname, urlObj.search].filter(compact).join('');
}
