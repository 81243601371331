import { useScreenSizeEnum, ScreenSize } from '@/components/ScreenSizes';
import { useApplicationContainer } from 'containers/ApplicationContainer';

export default function useScreenSize() {
  const [{ serverScreenSize }] = useApplicationContainer();

  if (typeof window === 'undefined' && !serverScreenSize) {
    throw '`useScreenSize` must be passed an initial size value on the server. Use `<ScreenSizes />` component instead.';
  }

  const screenSize = useScreenSizeEnum(serverScreenSize || window.innerWidth);

  return {
    width: typeof window !== 'undefined' ? window.innerWidth : undefined,
    isSmallScreen: screenSize === ScreenSize.SMALL,
    isMediumScreen: screenSize === ScreenSize.MEDIUM,
    // adding this for backwards compatibility
    isLargeScreen: screenSize === ScreenSize.LARGE || screenSize === ScreenSize.EXTRA_LARGE,
    isXtraLargeScreen: screenSize === ScreenSize.EXTRA_LARGE
  };
}
