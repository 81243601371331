import { ComponentType, PropsWithChildren, ReactNode, createContext, useContext } from 'react';

export function createContainer<StateType, MethodsType, PropsType>(
  func: (props: PropsType) => [StateType, MethodsType] | [StateType, MethodsType, (children: ReactNode) => ReactNode]
) {
  const Context = createContext<[StateType, MethodsType]>(undefined!);

  function Container(props: PropsWithChildren<PropsType>) {
    const [state, methods, content] = func(props);

    return <Context.Provider value={[state, methods]}>{content ? content(props.children) : props.children}</Context.Provider>;
  }

  function useContainer() {
    return useContext(Context);
  }

  function withContainer<ComponentProps>(Component: ComponentType<ComponentProps>, props?: PropsType) {
    return (componentProps: PropsWithChildren<ComponentProps>) => (
      <Container {...(props as PropsType)}>
        <Component {...componentProps} />
      </Container>
    );
  }

  return [Container, useContainer, withContainer] as const;
}
