import { ImgHTMLAttributes, useEffect, useState, useRef, useCallback, SyntheticEvent } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from 'components/styles/Image.module.scss';
import cn from 'classnames';

const WIDTHS = [60, 192];
const ARTICLE_WIDTHS = [60, 192, 360, 620, 1250, 2160];
const LEGACY_CDN = 'https://d3vbd4zrteu05a.cloudfront.net'; // remove after next deployment
const CDN = 'https://assets.scorebooklive.com';
const GRAPHICS_DOMAIN = CDN; // change this for testing graphics app

export interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {}

export default function Image({ src: imageSrc, className, onLoad, onError, ...props }: ImageProps) {
  const ref = useRef<HTMLImageElement>();
  const [inViewRef, inView] = useInView();
  const [src, setSrc] = useState<string>();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (inView && imageSrc) {
      const [domain, path] = imageSrc.split('/uploads/');
      if (path && !path.endsWith('.svg') && (domain === CDN || domain === LEGACY_CDN) && ref.current) {
        const containerWidth = ref.current.offsetWidth * window.devicePixelRatio;
        const width = WIDTHS.find(width => containerWidth <= width);
        const size = width ? `${width}x/` : '';
        setSrc(`${GRAPHICS_DOMAIN}/r/${size}uploads/${path}.webp`);
      } else if (/https:\/\/highschool.(si|athlonsports).com\/\.image/.test(domain) && ref.current) {
        const containerWidth = ref.current.offsetWidth * window.devicePixelRatio;
        const width = ARTICLE_WIDTHS.find(width => containerWidth <= width) || 620;
        const [news_image_domain, news_image_path] = imageSrc.split('.image');
        setSrc(`${news_image_domain}.image/q_auto:good,w_${width}/f_auto/${news_image_path}.webp`);
      } else if (/minutemediacdn\.com/.test(domain) && ref.current) {
        const containerWidth = ref.current.offsetWidth * window.devicePixelRatio;
        const width = ARTICLE_WIDTHS.find(width => containerWidth <= width) || 620;
        setSrc(imageSrc.replace(/w_\d+/, `w_${width}`));
      } else {
        setSrc(imageSrc);
      }
    }
  }, [inView, imageSrc]);

  const setRefs = useCallback(
    (node: HTMLImageElement) => {
      ref.current = node;
      inViewRef(node);
    },
    [inViewRef]
  );

  function handleLoad(e: SyntheticEvent<HTMLImageElement, Event>) {
    setIsLoaded(true);
    if (onLoad) onLoad(e);
  }

  function handleError(e: SyntheticEvent<HTMLImageElement, Event>) {
    if (src != imageSrc) {
      setSrc(imageSrc);
    } else if (onError) {
      onError(e);
    }
  }

  return (
    <img
      ref={setRefs}
      src={src}
      onLoad={handleLoad}
      onError={handleError}
      className={cn(className, styles.Image, { [styles.ImageLoaded]: isLoaded })}
      {...props}
    />
  );
}
