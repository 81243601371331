import Modal from '@/components/Modal';
import Link from 'components/Link';
import Button, { ButtonSize, ButtonWidth } from './Button';
import styles from './styles/SiHsPartnerModal.module.scss';
import SBLiveLogo from 'images/icons/sblive-logo.png';
import Image from 'components/Image';

export default function SiHsPartnerModal({ onClose }: { onClose: (accepted?: boolean) => void }) {
  return (
    <Modal onClose={onClose} title={<Image src={SBLiveLogo} className={styles.Logo} />} fullScreenSmall={false}>
      <p className={styles.Message}>
        This page is operated by SBLive Sports, a third-party Sports Illustrated partner. Use of this page is governed by SBLive’s{' '}
        <Link href="/privacy">Privacy Policy</Link> and <Link href="/terms">Terms & Conditions</Link>.
      </p>
      <Button onClick={() => onClose(true)} width={ButtonWidth.FULL} size={ButtonSize.LARGE}>
        Got It!
      </Button>
    </Modal>
  );
}
