import cn from 'classnames';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import styles from './styles/ToastMessage.module.scss';

export enum ToastMessageType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING'
}

interface ToastMessageProps {
  type: ToastMessageType;
  message: string;
  body?: string;
  onClose: () => void;
  timeout?: number | false;
  style?: CSSProperties;
  className?: string;
}

export function ToastMessage({ type, message, body, onClose, timeout = 5000, style, className }: ToastMessageProps) {
  const closeTimer = useRef<ReturnType<typeof setTimeout>>();
  const [isTimedOut, setIsTimedOut] = useState(false);

  useEffect(() => {
    if (timeout) {
      closeTimer.current = setTimeout(() => setIsTimedOut(true), timeout);
      return () => {
        if (closeTimer.current) clearTimeout(closeTimer.current);
      };
    }
  }, [timeout]);

  function handleAnimationEnd() {
    if (isTimedOut) onClose();
  }

  return (
    <div
      className={cn(styles.Container, styles[type], { [styles.FadeOut]: isTimedOut }, styles.BottomRightFixed)}
      onClick={onClose}
      style={style}
      onAnimationEnd={handleAnimationEnd}
    >
      <svg width="33" height="33" viewBox="0 0 33 33">
        <circle cx="16.5" cy="16.5" r="16.5" className={styles.OuterCircle} />
        <circle cx="16.5" cy="16.5" r="12" className={styles.Circle} />
        {type === ToastMessageType.SUCCESS ? (
          <path d="M10.5 17.3889L14.1175 20.5L22.5 12.5" stroke="white" strokeWidth="2" strokeLinecap="square" fill="none" />
        ) : (
          <>
            <path d="M16.5 12V18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.5 22H16.51" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </>
        )}
      </svg>
      <div className={styles.Text}>
        <div className={styles.Message}>{message}</div>
        {body && <div className={styles.Body}>{body}</div>}
      </div>
      <svg width="10" height="11">
        <path d="M9 1.5L1 9.5" stroke="#636363" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1 1.5L9 9.5" stroke="#636363" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
}
