import { ReactNode, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './styles/ScreenSizes.module.scss';
import { useApplicationContainer } from 'containers/ApplicationContainer';
import compact from 'helpers/compact';

export enum ScreenSize {
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large',
  EXTRA_LARGE = 'ExtraLarge'
}

function getScreenSize(width?: ScreenSize | number) {
  if (!width) {
    return;
  } else if (typeof width !== 'number') {
    return width;
  } else if (width <= 768) {
    return ScreenSize.SMALL;
  } else if (width < 1024) {
    return ScreenSize.MEDIUM;
  } else if (width < 1280) {
    return ScreenSize.LARGE;
  } else {
    return ScreenSize.EXTRA_LARGE;
  }
}

export function useScreenSizeEnum(width?: ScreenSize | number) {
  const [screenSize, setScreenSize] = useState<ScreenSize | undefined>(getScreenSize(width));

  useEffect(() => {
    function handleResize() {
      setScreenSize(getScreenSize(window.innerWidth));
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return screenSize;
}

export default function ScreenSizes({
  show,
  hide,
  children
}: {
  show?: ScreenSize | ScreenSize[];
  hide?: ScreenSize | ScreenSize[];
  children: ReactNode;
}) {
  const [{ serverScreenSize }] = useApplicationContainer();
  const screenSize = useScreenSizeEnum(serverScreenSize);

  const hideSizes = [hide].flat().filter(Boolean);
  const showSizes = [show].flat().filter(Boolean);

  if (!screenSize) {
    return (
      <div
        className={cn(
          styles.Container,
          hideSizes.filter(compact).map(size => styles[`Hide${size}`]),
          showSizes.filter(compact).map(size => styles[`Show${size}`])
        )}
      >
        {children}
      </div>
    );
  }

  if (hideSizes.includes(screenSize) || (showSizes.length > 0 && !showSizes.includes(screenSize))) {
    return null;
  }

  return <>{children}</>;
}
