import styles from './styles/Button.module.scss';
import cn from 'classnames';
import Clickable, { ClickableProps } from './Clickable';

export enum ButtonVariant {
  BLUE = 'Blue',
  DARK_BLUE = 'DarkBlue',
  GRAY = 'Gray',
  GRAY_NEGATIVE = 'GrayNegative',
  DARK_GRAY = 'DarkGray',
  RED = 'Red',
  WHITE = 'White'
}

export enum ButtonSize {
  EXTRA_SMALL = 'ExtraSmall',
  SMALL = 'Small',
  MEDIUM = 'Medium',
  LARGE = 'Large'
}

export enum ButtonWidth {
  WIDE = 'Wide',
  FULL = 'Full'
}

export type ButtonProps = ClickableProps & {
  size?: ButtonSize;
  variant?: ButtonVariant;
  width?: ButtonWidth;
  hover?: boolean;
};

export default function Button({ size = ButtonSize.MEDIUM, variant = ButtonVariant.BLUE, width, className, hover, ...props }: ButtonProps) {
  return (
    <Clickable
      {...props}
      className={cn(
        styles.Button,
        styles[variant],
        styles[`Size${size}`],
        width && styles[`Width${width}`],
        hover && [styles.Hover],
        className
      )}
    />
  );
}
